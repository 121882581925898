












































































































































































































































































































import HrefLink from '@/components/elements/HrefLink.vue';
import BaseSite from '@/views/BaseSite.vue';
import {Component, Prop, Vue} from "vue-property-decorator";

@Component({
             components: {HrefLink}
           })
export default class DatenschutzContent extends BaseSite {
  private dialog: boolean = false;


  // Opt-out function
  private gaOptout() {
    document.cookie = this.disableStr + '=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
    window[this.disableStr] = true;
  }

}
