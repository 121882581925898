import { render, staticRenderFns } from "./SiteDatenschutz.vue?vue&type=template&id=5e83b546&"
import script from "./SiteDatenschutz.vue?vue&type=script&lang=ts&"
export * from "./SiteDatenschutz.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!/home/florian/Projects/get-a-shop-website/vuetify_getashop_promote/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
installComponents(component, {VContainer,VLayout})
