

















import DatenschutzContent from '@/components/contents/DatenschutzContent.vue';
import UndersideHeader from '@/components/elements/UndersideHeader.vue';
import BaseSite from "@/views/BaseSite.vue";
import Page from "@/components/elements/Page.vue";
import {Component, Vue} from "vue-property-decorator";

@Component({
             components: {
               UndersideHeader,
               DatenschutzContent,
               Page
             }
           })
export default class Datenschutz extends BaseSite {
  private datenschutzPage: object = {menuActive:false};
}
